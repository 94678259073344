import { Box, Typography, Button } from "@mui/material";
import Banner from "../img/BannerSforzi.png";
import BannerMobile from "../img/banner-sforzi-mobile.png";
import Navbar from "../components/navbar";
import Form from "../components/form";
import Cards from "../components/cards";
import sforziIcon from "../img/sforzi-icon.png";
import sforziIconWhiteBG from "../img/sforzi-icon-whitebg.png";
import sforziLogoGrande from "../img/sforzi-logo-grande.png";
import imgLeve from "../img/leve.png";
import img1 from "../img/como-sao-nossas-aulas.JPG";
import img6 from "../img/img6.png";
import img7 from "../img/img7.png";
import img8 from "../img/img8.png";
import igicon from "../img/igicon.png";
import fbicon from "../img/fbicon.png";
import wppicon from "../img/wppicon.png";
import locsforzi from "../img/loc-sforzi.jpg";
import Footer from "../components/footer";
import Map from "../components/map";
import imgProfessora from "../img/professora.jpg";

function App() {
  return (
    <Box sx={{ width: "100%", overflowX: "hidden" }}>
      <Navbar />
      <Box
        sx={{
          width: "100%",
          height: { md: "580px", xs: "270px" },
          backgroundImage: { md: `url(${Banner})`, xs: `url(${BannerMobile})` },
          backgroundPosition: { md: "center right", xs: "center" },
          marginTop: { xs: "-1.1em" },
          backgroundRepeat: "no-repeat",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        id="home"
      >
        <Box
          sx={{
            width: "60%",
            display: { md: "flex", xs: "none" },
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Form />
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "#F39200",
          marginTop: { md: "-0.2em", xs: "-1.2em" },
          display: "flex",
          flexFlow: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingBottom: "2.5em",
          height: "min-content",
        }}
      >
        <Box
          sx={{
            width: "90%",
            marginTop: { md: "3em", xs: "3em" },
            display: { md: "block", xs: "flex" },
            alignItems: { md: "start", xs: "center" },
            justifyContent: { md: "flex-start", xs: "center" },
            flexFlow: { md: "", xs: " column" },
          }}
        >
          <Box sx={{ display: { md: "none", xs: "block" } }}>
            <Form />
          </Box>
        </Box>
        <Cards />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexFlow: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ width: { xs: "75%", md: "95%" } }}>
          <Typography
            sx={{
              fontSize: { md: "28px", xs: "24px" },
              fontWeight: "bold",
              textAlign: { xs: "center", md: "start" },
              marginTop: "2em",
            }}
            id="servicos-solucoes"
          >
            COMO SÃO NOSSAS AULAS
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexFlow: { md: "row", xs: "column-reverse" },
              marginTop: "2em",
            }}
          >
            <Box
              sx={{
                width: { md: "50%", xs: "100%" },
                alignItems: "center",
                justifyContent: "flex-start",
                marginTop: "2em",
                display: { md: "flex", xs: "none" },
              }}
            >
              <img
                src={img1}
                alt="professora e aluna"
                style={{
                  width: "80%",
                  borderRadius: "12px",
                  boxShadow: "20px 20px 25px 2px #00000040",
                }}
              />
            </Box>
            <Box
              sx={{
                width: { md: "50%", xs: "100%" },
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "2em",
              }}
            >
              <Box
                sx={{
                  width: { md: "90%", xs: "95%" },
                  border: "3px solid #F39200",
                  borderRadius: "12px",
                  padding: { md: "2.5em", xl: "4em", xs: "2.5em" },
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    left: { md: "80%", xs: "95%" },
                    top: { md: "0", xs: "-25px" },
                  }}
                >
                  <img
                    src={sforziIcon}
                    alt="icone sforzi"
                    style={{
                      width: "18%",
                      height: "auto",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    fontSize: { xl: "24px", md: "20px", xs: "16px" },
                    width: { md: "80%", xs: "230px" },
                  }}
                >
                  <ul
                    style={{
                      marginTop: "-21%",
                      width: "100%",
                    }}
                  >
                    <li
                      style={{
                        marginTop: "1em",
                        width: "80%",
                      }}
                    >
                      Aulas a partir de 60 minutos
                    </li>
                    <li
                      style={{
                        marginTop: "1em",
                        width: "80%",
                      }}
                    >
                      Presenciais ou online, você escolhe
                    </li>
                    <li
                      style={{
                        marginTop: "1em",
                        width: "80%",
                      }}
                    >
                      Professores especialistas em cada área
                    </li>
                    <li
                      style={{
                        marginTop: "1em",
                        width: "80%",
                      }}
                    >
                      As aulas são exclusivas e personalizadas
                    </li>
                    <li
                      style={{
                        marginTop: "1em",
                        width: "80%",
                      }}
                    >
                      Tecnologia e aplicativos para facilitar seus avanços
                    </li>
                  </ul>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "4em",
        }}
      >
        <Box
          sx={{
            width: "90%",
            alignItems: "center",
            justifyContent: "space-between",
            display: "flex",
            flexFlow: { md: "row", xs: "column" },
          }}
        >
          <Box
            sx={{
              width: { md: "50%", xs: "100%" },
              display: "flex",
              flexFlow: "column",
              alignItems: { md: "flex-start", xs: "center" },
              justifyContent: { md: "flex-start", xs: "center" },
            }}
          >
            <Typography
              sx={{
                fontSize: { md: "28px", xs: "24px" },
                fontWeight: "bold",
                marginTop: "2em",
                alignSelf: "flex-start",
              }}
            >
              NOSSA METODOLOGIA
            </Typography>
            <Box
              sx={{
                marginTop: "2em",
                fontSize: "20px",
              }}
            >
              <span>
                Nossa metodologia inédita{" "}
                <b style={{ fontSize: "25px", color: "#94C11F" }}>L.E.V.E.</b>{" "}
                une técnicas de estudo e gestão de tempo para favorecer o
                aprendizado de forma eficaz e a retenção de informações em uma
                hora de estudos, com 4 pilares importantes:
              </span>
            </Box>
            <Box
              sx={{
                display: { md: "none", xs: "flex" },
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "1em",
              }}
            >
              <img
                src={imgLeve}
                alt="L.e.v.e."
                style={{ width: "60%", height: "auto" }}
              />
            </Box>
            <Box
              sx={{
                marginTop: "2em",
                fontSize: "20px",
              }}
            >
              <span
                style={{
                  textAlign: { xs: "center", md: "start" },
                }}
              >
                <b>LEIA:</b> Dedique alguns minutos para ler o conteúdo que
                precisa estudar.
              </span>
            </Box>
            <Box
              sx={{
                marginTop: "2em",
                fontSize: "20px",
              }}
            >
              <span
                style={{
                  textAlign: { xs: "center", md: "start" },
                }}
              >
                <b>ESCREVA:</b> Faça um breve resumo destacando o que você
                aprendeu.
              </span>
            </Box>
            <Box
              sx={{
                marginTop: "2em",
                fontSize: "20px",
              }}
            >
              <span
                style={{
                  textAlign: { xs: "center", md: "start" },
                }}
              >
                <b>VÍDEO:</b> Assista a um vídeo informativo sobre o assunto.
              </span>
            </Box>
            <Box
              sx={{
                marginTop: "2em",
                fontSize: "20px",
              }}
            >
              <span
                style={{
                  textAlign: { xs: "center", md: "start" },
                }}
              >
                <b>EXERCITE:</b> Resolva exercícios sobre a temática.
              </span>
            </Box>
          </Box>
          <Box
            sx={{
              width: { md: "50%", xs: "100%" },
              display: { md: "flex", xs: "none" },
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={imgLeve}
              alt="L.e.v.e."
              style={{ width: "40%", height: "auto", marginTop: "10em" }}
            />
          </Box>
        </Box>
      </Box>

      <Box
        id="servicos"
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "4em",
        }}
      >
        <Box
          sx={{
            width: "90%",
          }}
        >
          <Typography
            sx={{
              fontSize: { md: "28px", xs: "24px" },
              fontWeight: "bold",
              marginTop: "2em",
              textAlign: { md: "start", xs: "center" },
            }}
          >
            NOSSAS TUTORIAS
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexFlow: { md: "row", xs: "column" },
            }}
          >
            <Box sx={{ width: { md: "50%", xs: "100%" }, marginTop: "2em" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: { md: "start", xs: "center" },
                  justifyContent: "center",
                  marginTop: "1em",
                  flexFlow: { md: "row", xs: "column" },
                }}
              >
                <img
                  src={img7}
                  alt="gabarito"
                  style={{
                    width: "180px",
                    height: "auto",
                    borderRadius: "12px",
                    border: "3px solid #F39200",
                  }}
                />
                <Box sx={{ marginLeft: "1em" }}>
                  <Typography
                    sx={{
                      fontSize: { md: "24px", xs: "20px" },
                      alignSelf: "flex-start",
                      marginTop: { md: "0", xs: "1em" },
                      color: "#94C11F",
                    }}
                  >
                    PARA VESTIBULAR E CURSOS
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      alignSelf: "flex-start",
                    }}
                  >
                    Aulas exclusivas que incluem simulado com questões,
                    planejamento de rotina para melhor performance, plataformas
                    e aplicativos para estudo e acompanhamento de resultados.
                    Contratação mínima de 3 meses.
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: { md: "start", xs: "center" },
                  justifyContent: "center",
                  marginTop: "1em",
                  flexFlow: { md: "row", xs: "column" },
                }}
              >
                <img
                  src={img8}
                  alt="livros e maçã"
                  style={{
                    width: "180px",
                    height: "auto",
                    borderRadius: "12px",
                    border: "3px solid #F39200",
                  }}
                />
                <Box sx={{ marginLeft: "1em" }}>
                  <Typography
                    sx={{
                      fontSize: { md: "24px", xs: "20px" },
                      alignSelf: "flex-start",
                      marginTop: { md: "0", xs: "1em" },
                      color: "#94C11F",
                    }}
                  >
                    PARA ROTINA
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      alignSelf: "flex-start",
                    }}
                  >
                    Tutoria quinzenal que inclui planejamento de rotina com
                    ferramentas personalizadas e acompanhamento de performance. 
                    Contratação mínima de 2 meses.
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: { md: "start", xs: "center" },
                  justifyContent: "center",
                  marginTop: "1em",
                  flexFlow: { md: "row", xs: "column" },
                }}
              >
                <img
                  src={img6}
                  alt="livros e maçã"
                  style={{
                    width: "180px",
                    height: "auto",
                    borderRadius: "12px",
                    border: "3px solid #F39200",
                  }}
                />
                <Box sx={{ marginLeft: "1em" }}>
                  <Typography
                    sx={{
                      fontSize: { md: "24px", xs: "20px" },
                      alignSelf: "flex-start",
                      marginTop: { md: "0", xs: "1em" },
                      color: "#94C11F",
                    }}
                  >
                    PARA ESCOLA
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      alignSelf: "flex-start",
                    }}
                  >
                    Aulas exclusivas que incluem correção de provas e exercícios
                    e reajuste de rotina para melhor desempenho nos estudos.
                    Contratação mínima de 2 meses.
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                width: { md: "30%", xs: "100%" },
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <a href="https://api.whatsapp.com/send?phone=5545991297864&text=Ol%c3%a1!+Visitei+o+site+de+voc%c3%aas+e+quero+conhecer+mais+sobre+as+tutorias+da+Sforzi">
                <Button
                  sx={{
                    padding: "1em",
                    fontSize: "26px",
                    backgroundColor: "#F39200",
                    color: "white",
                    borderRadius: "12px",
                    border: "2px solid transparent",
                    ":hover": {
                      border: "2px solid #F39200",
                      color: "#F39200",
                      backgroundColor: "white",
                    },

                    marginTop: { md: "0", xs: "2em" },
                  }}
                >
                  Comece sua Tutoria
                  <img
                    src={sforziIconWhiteBG}
                    alt="icone sforzi"
                    style={{
                      width: "60px",
                      height: "auto",
                      marginLeft: "1em",
                    }}
                  />
                </Button>
              </a>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        id="servicos"
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "4em",
        }}
      >
        <Box
          sx={{
            width: "90%",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexFlow: { md: "row", xs: "column" },
            }}
          >
            <Box
              sx={{ width: "100%", overflowX: "hidden", paddingBottom: "4em" }}
            >
              <Typography
                sx={{
                  fontSize: { md: "28px", xs: "24px" },
                  fontWeight: "bold",
                  marginTop: "2em",
                  textAlign: { md: "start", xs: "center" },
                }}
              >
                QUEM SOMOS
              </Typography>
              <Box
                id="quemSomos"
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: "100",
                    display: "flex",
                    flexFlow: { md: "row", xs: "column" },
                    alignItems: "center",
                    justifyContent: { md: "space-between", xs: "center" },
                    marginTop: "5em",
                  }}
                >
                  <Box
                    sx={{
                      width: { md: "50%", xs: "100%" },
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <img
                      src={imgProfessora}
                      alt="logo sforzi"
                      style={{ width: "500px", borderRadius: "8px" }}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: { md: "50%", xs: "100%" },
                      display: "flex",
                      flexFlow: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    id="quem-somos"
                  >
                    <Typography
                      sx={{
                        fontSize: "20px",
                        marginTop: "2em",
                        width: { md: "90%", xs: "65%" },
                      }}
                    >
                      A Sforzi Tutoria Inteligente, fundada em 2019 por Milena
                      Giani em Foz do Iguaçu, Paraná, oferece uma metodologia
                      inovadora para ajudar as pessoas a alcançarem autonomia e
                      alto desempenho nos estudos. Através da metodologia
                      L.E.V.E., estudantes de todas as idades desenvolvem
                      habilidades para absorver conhecimento de forma
                      duradoura.  Com aulas presenciais e online, personalizadas
                      por professores especialistas nas disciplinas
                      selecionadas, que utilizam técnicas de aprendizagem
                      modernas e psicopedagógicas, a Sforzi é um espaço seguro
                      para o desenvolvimento e evolução dos estudantes, indo
                      além do reforço escolar.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingBottom: "2em",
        }}
      >
        <Box
          sx={{
            width: "90%",
            display: "flex",
            flexFlow: { md: "row", xs: "column" },
            alignItems: "center",
            justifyContent: { md: "flex-start", xs: "center" },
            marginTop: { md: "10em", xs: "5em" },
          }}
        >
          <Box
            sx={{
              width: { md: "50%", xs: "100%" },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexFlow: "column",
            }}
            id="fale-conosco"
          >
            <Typography
              sx={{
                fontSize: { md: "28px", xs: "24px" },
                fontWeight: "bold",
                marginTop: "2em",
              }}
            >
              CONVERSE COM A GENTE
            </Typography>
            <Typography
              sx={{
                fontSize: { md: "24px", xs: "20px" },
                marginTop: "2em",
                textAlign: "center",
                width: { md: "70%", xs: "100%" },
              }}
            >
              Avenida Paraná 1030 - Sala 6, Foz do Iguaçu, PR, Brazil, 85852-000
            </Typography>
            <Box
              sx={{
                width: "80%",
                display: { md: "flex", xs: "none" },
                alignItems: "center",
                justifyContent: "space-evenly",
                flexDirection: "row",
                marginTop: "2em",
                color: "#F39200",
              }}
            >
              <a href="https://www.instagram.com/sforzi.tutoriainteligente/?igshid=MmU2YjMzNjRlOQ%3D%3D">
                <img
                  src={igicon}
                  alt="logo do instagram"
                  style={{ width: "40px" }}
                />
              </a>
              <a href="https://www.facebook.com/sforzitutoria/">
                <img
                  src={fbicon}
                  alt="logo do facebook"
                  style={{ width: "40px" }}
                />
              </a>
              <a href="https://api.whatsapp.com/send?phone=5545991297864&text=Ol%c3%a1!+Visitei+o+site+de+voc%c3%aas+e+quero+conhecer+mais+sobre+as+tutorias+da+Sforzi.">
                <img
                  src={wppicon}
                  alt="logo do whatsapp"
                  style={{ width: "40px" }}
                />
              </a>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: { md: "none", xs: "flex" },
                alignItems: "center",
                justifyContent: "space-evenly",
                flexDirection: "row",
                marginTop: "2em",
                color: "#F39200",
              }}
            >
              <a href="https://www.instagram.com/sforzi.tutoriainteligente/?igshid=MmU2YjMzNjRlOQ%3D%3D">
                <img
                  src={igicon}
                  alt="logo do instagram"
                  style={{ width: "35px" }}
                />
              </a>
              <a href="https://www.facebook.com/sforzitutoria/">
                <img
                  src={fbicon}
                  alt="logo do facebook"
                  style={{ width: "35px" }}
                />
              </a>
              <a href="https://api.whatsapp.com/send?phone=5545991297864&text=Ol%c3%a1!+Visitei+o+site+de+voc%c3%aas+e+quero+conhecer+mais+sobre+as+tutorias+da+Sforzi.">
                <img
                  src={wppicon}
                  alt="logo do whatsapp"
                  style={{ width: "35px" }}
                />
              </a>
            </Box>
          </Box>
          <Box
            sx={{
              width: { md: "50%", xs: "90%" },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexFlow: "column",
              marginTop: { md: "0", xs: "2em" },
              marginBottom: { md: "0", xs: "-0.2em" },
            }}
          >
            <Map />
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}

export default App;
