import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { Box } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const Map = () => {
  const containerStyle = {
    width: "100%",
    height: "400px",
  };

  const center = {
    lat: -25.547404360195543,
    lng: -54.563809001477885,
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyC7E_-nPF1FysnlRYblUAWNJnCYrsg0o0Y">
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={15}>
        <Marker position={center} title="Sforzi Tutoria Inteligente"></Marker>
      </GoogleMap>
    </LoadScript>
  );
};

export default Map;
