import { useMemo, useState } from "react";
import {
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
} from "@mui/material";
import LogoSforzi from "../../img/logo_sforzi_cor 5.png";
import axios from "axios";

export default function Form() {
  const [step, setStep] = useState(0);
  const [stage, setStage] = useState(0);
  const [sender, setSender] = useState({
    nome: "",
    email: "",
    telefone: "",
    motivo_contato: "",
    grau_escolaridade: "",
    objetivo: "",
    conheceu_sforzi: "",
  });

  console.log("stage: " + stage);

  const senderconst = useMemo(
    () => ({
      nome: sender.nome,
      motivo_contato: sender.motivo_contato,
      email: sender.email,
      telefone: sender.telefone,
      grau_escolaridade: sender.grau_escolaridade,
      objetivo: sender.objetivo,
      conheceu_sforzi: sender.conheceu_sforzi,
    }),
    [sender]
  );

  function handleNextStep() {
    if (stage == 0) {
      if (sender.nome && sender.motivo_contato) {
        setStep(step + 1);
        setStage(stage + 1);
      } else {
        alert("Preencha todos os campos!");
      }
    }

    if (stage == 1) {
      if (sender.telefone && sender.email) {
        setStep(step + 1);
        setStage(stage + 1);
      } else {
        alert("Preencha todos os campos!");
      }
    }

    if (stage == 2) {
      if (
        sender.grau_escolaridade &&
        sender.objetivo &&
        sender.conheceu_sforzi
      ) {
        setStep(step + 1);
        setStage(stage + 1);
      } else {
        alert("Preencha todos os campos!");
      }
    }
  }

  const handleChange = ({ target }) => {
    setSender({
      ...sender,
      [target.name]: target.value,
    });
  };

  function handleSubmit() {
    axios
      .post(
        "https://sheet.best/api/sheets/75619b79-7db2-4ce7-b3df-91a7fc8801d0",
        senderconst
      )
      .then((response) => {
        handleNextStep();
      });
  }

  return (
    <Box
      sx={{
        width: { md: "320px", xs: "300px" },
        height: "420px",
        paddingX: "0.8em",
        paddingY: "1em",
        backgroundColor: "white",
        borderRadius: "12px",
        textAlign: "center",
        marginTop: "2em",
        boxShadow: "25px 25px 50px 0px #00000040",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <FormControl>
        {/* STEP 1 */}
        <Box sx={{ display: `${step === 0 ? "block" : "none"}` }}>
          <Typography sx={{ fontSize: "24px" }}>VAMOS COMEÇAR!</Typography>
          <Typography sx={{ fontSize: "18px", marginTop: "1em" }}>
            Qual o seu nome?
          </Typography>
          <input
            type="text"
            placeholder="Nome"
            name="nome"
            style={{
              border: "1px solid #94C11F",
              outline: "none",
              padding: "5px",
              fontSize: "18px",
              borderRadius: "4px",
              width: "205px",
            }}
            required
            onChange={handleChange}
            value={sender.nome}
          />
          <Typography sx={{ fontSize: "18px", marginTop: "1em" }}>
            A tutoria seria pra você?
          </Typography>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            required
          >
            <FormControlLabel
              name="motivo_contato"
              value="Sim"
              onChange={handleChange}
              control={
                <Radio
                  sx={{
                    color: "#94C11F",
                    "&.Mui-checked": {
                      color: "#F39200",
                    },
                  }}
                />
              }
              label="Sim"
            />
            <FormControlLabel
              name="motivo_contato"
              value="Para o filho"
              onChange={handleChange}
              control={
                <Radio
                  sx={{
                    color: "#94C11F",
                    "&.Mui-checked": {
                      color: "#F39200",
                    },
                  }}
                />
              }
              label="Para Meu filho(a)"
            />
          </RadioGroup>

          <button
            style={{
              backgroundColor: "#F39200",
              color: "white",
              fontSize: "16px",
              fontWeight: "600",
              marginTop: "1.5em",
              outline: "none",
              border: "1.5px solid transparent",
              padding: "1em",
              borderRadius: "8px",
              "&:hover": {
                backgroundColor: "white",
                color: "#F39200",
                border: "1.5px solid #F39200",
              },
            }}
            onClick={handleNextStep}
          >
            Continuar
          </button>
        </Box>
        {/* STEP 1 */}
        {/* STEP 2 */}
        <Box sx={{ display: `${step === 1 ? "block" : "none"}` }}>
          <Typography sx={{ fontSize: "24px" }}>
            ME PASSE SEU CONTATO:
          </Typography>
          <Typography sx={{ fontSize: "18px", marginTop: "1em" }}>
            Qual seu número de WhatsApp?
          </Typography>
          <input
            name="telefone"
            type="text"
            placeholder="Número"
            style={{
              border: "1px solid #94C11F",
              outline: "none",
              padding: "5px",
              fontSize: "18px",
              borderRadius: "4px",
              width: "205px",
            }}
            onChange={handleChange}
            value={sender.telefone}
          />
          <Typography sx={{ fontSize: "18px", marginTop: "1em" }}>
            Agora coloque aqui seu melhor <br /> e-mail:
          </Typography>
          <input
            name="email"
            type="text"
            placeholder="E-mail"
            style={{
              border: "1px solid #94C11F",
              outline: "none",
              padding: "5px",
              fontSize: "18px",
              borderRadius: "4px",
              width: "205px",
            }}
            onChange={handleChange}
            value={sender.email}
          />

          <button
            style={{
              backgroundColor: "#F39200",
              color: "white",
              fontSize: "16px",
              fontWeight: "600",
              marginTop: "1.5em",
              outline: "none",
              border: "1.5px solid transparent",
              padding: "1em",
              borderRadius: "8px",
              "&:hover": {
                backgroundColor: "white",
                color: "#F39200",
                border: "1.5px solid #F39200",
              },
            }}
            onClick={handleNextStep}
          >
            Continuar
          </button>
        </Box>
        {/* STEP 2 */}
        {/* STEP 3 */}
        <Box
          sx={{
            display: `${step === 2 ? "flex" : "none"}`,
            flexFlow: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography sx={{ fontSize: "24px" }}>
            ME CONTE UM POUCO MAIS:
          </Typography>
          <Typography sx={{ fontSize: "18px", marginTop: "1em" }}>
            Qual o grau de escolaridade?
          </Typography>

          <select
            style={{
              border: "1px solid #94C11F",
              outline: "none",
              padding: "5px",
              fontSize: "18px",
              borderRadius: "4px",
              width: "205px",
            }}
            value={sender.grau_escolaridade}
            onChange={(e) => {
              setSender({
                ...sender,
                grau_escolaridade: e.target.value,
              });
            }}
            name="grau_escolaridade"
          >
            <option value="opcao1" selected>
              Selecione
            </option>
            <option value="Fundamental">Ensino Fundamental</option>
            <option value="Médio">Ensino Médio</option>
            <option value="Superior">Ensino Superior</option>
            <option value="Pós">Pós e outros</option>
          </select>

          <Typography sx={{ fontSize: "18px", marginTop: "1em" }}>
            Em que podemos lhe ajudar?
          </Typography>

          <select
            style={{
              border: "1px solid #94C11F",
              outline: "none",
              padding: "5px",
              fontSize: "18px",
              borderRadius: "4px",
              width: "205px",
            }}
            value={sender.objetivo}
            onChange={(e) => {
              setSender({
                ...sender,
                objetivo: e.target.value,
              });
            }}
            name="objetivo"
          >
            <option value="vestibular">Passar em um vestibular</option>
            <option value="escolar">Reforço escolar</option>
            <option value="Organizar">Organizar estudos</option>
          </select>

          <Typography sx={{ fontSize: "18px", marginTop: "1em" }}>
            Aonde você conheceu a Sforzi?
          </Typography>
          <select
            style={{
              border: "1px solid #94C11F",
              outline: "none",
              padding: "5px",
              fontSize: "18px",
              borderRadius: "4px",
              width: "205px",
            }}
            value={sender.conheceu_sforzi}
            onChange={(e) => {
              setSender({
                ...sender,
                conheceu_sforzi: e.target.value,
              });
            }}
            name="conheceu_sforzi"
          >
            <option value="Facebook">Facebook</option>
            <option value="Instagram">Instagram</option>
            <option value="Indicação">Indicação</option>
            <option value="Google">Google</option>
            <option value="Outro">Outro</option>
          </select>

          <button
            style={{
              backgroundColor: "#F39200",
              color: "white",
              fontSize: "16px",
              fontWeight: "600",
              marginTop: "1.5em",
              outline: "none",
              border: "1.5px solid transparent",
              padding: "1em",
              borderRadius: "8px",
              "&:hover": {
                backgroundColor: "white",
                color: "#F39200",
                border: "1.5px solid #F39200",
              },
            }}
            onClick={handleNextStep}
          >
            Enviar
          </button>
        </Box>
        {/* STEP 3 */}
        {/* STEP 4 */}
        <Box sx={{ display: `${step === 3 ? "block" : "none"}` }}>
          <img
            src={LogoSforzi}
            alt="Logo Sforzi"
            style={{ width: "175px", height: "40px" }}
          />
          <Typography
            sx={{ fontSize: "22px", marginTop: "1em", fontWeight: "600" }}
          >
            AGRADECEMOS SEU CONTATO E OBRIGADO POR CONTAR MAIS SOBRE VOCÊ
          </Typography>
          <Typography
            sx={{ fontSize: "18px", marginTop: "1em", fontWeight: "500" }}
          >
            Logo nossa equipe vai te mandar mensagem para dar início a sua
            tutoria. Vamos juntos!
          </Typography>
        </Box>
        {/* STEP 4 */}
      </FormControl>
    </Box>
  );
}
