import { Typography, Box } from "@mui/material";
import animation from "../../img/007-whatsapp.json";
import Lottie from "lottie-react";

function Footer() {
  return (
    <Box
      sx={{
        width: "100%",
        padding: "1em",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#94C11F",
        marginTop: "5em",
      }}
    >
      <Box
        sx={{
          width: "60px",
          height: "60px",
          position: "fixed",
          left: { md: "95%", xs: "81%" },
          top: "90%",
        }}
      >
        <a href="https://api.whatsapp.com/send?phone=5545991297864&text=Ol%c3%a1!+Visitei+o+site+de+voc%c3%aas+e+quero+conhecer+mais+sobre+as+tutorias+da+Sforzi.">
          <Lottie animationData={animation} loop={true} />
        </a>
      </Box>
      <Typography sx={{ textAlign: { md: "start", xs: "center" } }}>
        © Copyright 2023 | SFORZI Tutoria Inteligente | Todos os direitos
        reservados
      </Typography>
    </Box>
  );
}

export default Footer;
