import { Box, Modal, Button } from "@mui/material";
import Logo from "../../img/logo_sforzi_cor 5.png";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { useState, useEffect } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import wppicon from "../../img/wppicon.png";
import { Link } from "react-router-dom";

export default function Navbar() {
  const [showMenu, setShowMenu] = useState(true);

  useEffect(() => {
    setShowMenu(false);
  }, []);

  function handleClick() {
    setShowMenu(!showMenu);
  }

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "60px",
        boxShadow: "rgba(0, 0, 0, 0.12) 0px 2px 3px",
        position: "fixed",
        backgroundColor: "white",

        zIndex: "999",
      }}
    >
      <Box
        sx={{
          width: "95%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <img src={Logo} width={120} height={30} alt="logo sforzi" />
        <Box
          sx={{
            display: { md: "flex", xs: "none" },
            alignItems: "center",
            justifyContent: "space-evenly",
            width: { md: "40%", lg: "50%" },
            fontSize: "20px",
          }}
        >
          <a href="/" style={{ textDecoration: "none", color: "black" }}>
            Home
          </a>
          {/*           <a
            href="/sobrenos"
            style={{ textDecoration: "none", color: "black" }}
          >
            Quem Somos
          </a> */}
          {/* <a href="#" style={{ textDecoration: "none", color: "black" }}>
            Nosso Time
          </a> */}
          <a
            href="#servicos"
            style={{ textDecoration: "none", color: "black" }}
          >
            Tutorias
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=5545991297864&text=Ol%c3%a1!+Visitei+o+site+de+voc%c3%aas+e+quero+conhecer+mais+sobre+as+tutorias+da+Sforzi."
            style={{
              textDecoration: "none",
              color: "black",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Converse com a gente
            <img
              src={wppicon}
              alt="logo do whatsapp"
              style={{ width: "20px", marginLeft: "0.5em" }}
            />
          </a>
        </Box>
        <Button
          sx={{
            display: { md: "none", xs: "flex" },
            alignItems: "center",
            justifyContent: "center",
            color: "black",
          }}
          onClick={handleClick}
        >
          <MenuRoundedIcon sx={{ width: "30px", height: "30px" }} />
        </Button>
        <Modal
          open={showMenu}
          onClose={handleClick}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              height: "100%",
              width: "60%",
              display: { md: "none", xs: "flex" },
              justifyContent: "flex-start",
              alignItems: "flex-start",
              backgroundColor: "White",
              padding: "1em",
              left: "40%",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: { md: "none", xs: "flex" },
                flexFlow: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                fontSize: "20px",
              }}
            >
              <Button
                sx={{
                  display: { md: "none", xs: "flex" },
                  alignItems: "center",
                  justifyContent: "center",
                  color: "black",
                  alignSelf: "flex-end",
                }}
                onClick={handleClick}
              >
                <CloseRoundedIcon sx={{ height: "30px", width: "30px" }} />
              </Button>
              <a
                href="#home"
                style={{
                  textDecoration: "none",
                  color: "black",
                  marginTop: "1.2em",
                }}
              >
                Home
              </a>
              {/*               <a
                href="/sobrenos"
                style={{
                  textDecoration: "none",
                  color: "black",
                  marginTop: "1.2em",
                }}
              >
                Quem Somos
              </a> */}
              <a
                href="#servicos-solucoes"
                style={{
                  textDecoration: "none",
                  color: "black",
                  marginTop: "1.2em",
                }}
              >
                Tutorias
              </a>
              <a
                href="https://api.whatsapp.com/send?phone=5545991297864&text=Ol%c3%a1!+Visitei+o+site+de+voc%c3%aas+e+quero+conhecer+mais+sobre+as+tutorias+da+Sforzi."
                style={{
                  textDecoration: "none",
                  color: "black",
                  marginTop: "1.2em",
                }}
              >
                Fale Conosco
                <img
                  src={wppicon}
                  alt="logo do whatsapp"
                  style={{ width: "20px", marginLeft: "0.5em" }}
                />
              </a>
            </Box>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
}
