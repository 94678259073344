import { Typography, Box } from "@mui/material";
import Navbar from "../components/navbar";
import sforziLogoGrande from "../img/sforzi-logo-grande.png";
import Footer from "../components/footer";

function SobreNos() {
  return (
    <>
      <Box sx={{ width: "100%", overflowX: "hidden", paddingBottom: "4em" }}>
        <Navbar />
        <Typography
          sx={{
            fontSize: { md: "28px", xs: "24px" },
            fontWeight: "bold",
            marginTop: "5em",
            textAlign: "center",
          }}
        >
          QUEM SOMOS
        </Typography>
        <Box
          id="quemSomos"
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "90%",
              display: "flex",
              flexFlow: { md: "row", xs: "column" },
              alignItems: "center",
              justifyContent: { md: "space-between", xs: "center" },
              marginTop: "5em",
            }}
          >
            <Box
              sx={{
                width: { md: "50%", xs: "100%" },
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={sforziLogoGrande}
                alt="logo sforzi"
                style={{ width: "280px" }}
              />
            </Box>
            <Box
              sx={{
                width: { md: "50%", xs: "100%" },
                display: "flex",
                flexFlow: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="quem-somos"
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  marginTop: "2em",
                }}
              >
                Muito além de reforço escolar, a Sforzi Tutoria Inteligente
                iniciou suas atividades em 2019 com o propósito de ajudar as
                pessoas a terem autonomia e alta performance nos estudos por
                meio de uma metodologia inédita.
              </Typography>
              <Typography
                sx={{
                  fontSize: "20px",
                  marginTop: "2em",
                }}
              >
                A Sforzi foi fundada por Milena Giani, professora graduada em
                Química e especialista em Psicopedagogia, em Foz do Iguaçu,
                Paraná, que vivenciou na prática durante anos em sala de aula as
                diversas queixas dos alunos em relação ao processo de
                aprendizagem. Com essa bagagem em mãos e continuamente estando
                em sala de aula, Milena montou uma metodologia própria chamada
                L.E.V.E, onde o estudante, independente da idade e escolaridade,
                desenvolve um caminho saudável e inteligente para absorver
                conhecimento para a vida toda, não somente para uma prova ou
                “passar de ano”. Na Sforzi, as aulas podem acontecer de forma
                presencial e online, a escolha dos pais ou aluno, sendo um
                espaço seguro para o desenvolvimento e evolução do estudante. Os
                professores são especialistas nas disciplinas selecionadas,
                focados em uma tutoria personalizada para cada caso, utilizando
                técnicas de aprendizagem modernas e psicopedagógicas.
              </Typography>
              <Typography
                sx={{
                  fontSize: "20px",
                  marginTop: "2em",
                }}
              >
                Com essa bagagem em mãos e continuamente estando em sala de
                aula, Milena montou uma metodologia própria chamada L.E.V.E,
                onde o estudante, independente da idade e escolaridade,
                desenvolve um caminho saudável e inteligente para absorver
                conhecimento para a vida toda, não somente para uma prova ou
                “passar de ano”. Na Sforzi, as aulas podem acontecer de forma
                presencial e online, a escolha dos pais ou aluno, sendo um
                espaço seguro para o desenvolvimento e evolução do estudante. Os
                professores são especialistas nas disciplinas selecionadas,
                focados em uma tutoria personalizada para cada caso, utilizando
                técnicas de aprendizagem modernas e psicopedagógicas.
              </Typography>
              <Typography
                sx={{
                  fontSize: "20px",
                  marginTop: "2em",
                }}
              >
                Na Sforzi, as aulas podem acontecer de forma presencial e
                online, a escolha dos pais ou aluno, sendo um espaço seguro para
                o desenvolvimento e evolução do estudante. Os professores são
                especialistas nas disciplinas selecionadas, focados em uma
                tutoria personalizada para cada caso, utilizando técnicas de
                aprendizagem modernas e psicopedagógicas.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
}

export default SobreNos;
