import { Box, Typography } from "@mui/material";
import img1 from "../../img/img1.png";
import img2 from "../../img/segundo-passo.JPG";
import img3 from "../../img/img3.png";
import img4 from "../../img/img4.png";

export default function Cards() {
  const cardsData = [
    {
      img: img1,
      title: "Primeiro Passo:",
      text: "Preencher um formulário para entendermos como podemos te orientar e qual a melhor tutoria para o seu momento",
    },
    {
      img: img2,
      title: "Segundo Passo:",
      text: "Vamos bater um papo para conhecer você, sua rotina e seus objetivos que você procura na tutoria.",
    },
    {
      img: img3,
      title: "Terceiro Passo:",
      text: "Com seu planejamento em mãos, hora de começar a estudar com alta performance.",
    },
    {
      img: img4,
      title: "Quarto Passo:",
      text: "Monitorar seus avanços com dados, comemorar e ajustar o que for preciso na jornada!",
    },
  ];
  return (
    <Box
      sx={{
        width: { xs: "75%", md: "95%", xl: "80%" },
        display: "flex",
        alignItems: "center",
        justifyContent: { md: "space-between", xs: "center" },
        flexFlow: { md: "row", xs: "column" },
        marginTop: "2em",
      }}
    >
      <Box sx={{ width: "100%", display: "flex", flexFlow: "column" }}>
        <Typography
          sx={{
            fontSize: { md: "28px", xs: "24px" },
            fontWeight: "bold",
            textAlign: { xs: "center", md: "start" },
            marginTop: "2em",
            marginBottom: "2em",
          }}
          id="servicos-solucoes"
        >
          COMO INICIAR SUA TUTORIA NA SFORZI
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexFlow: { md: "row", xs: "column" },
            justifyContent: "space-between",
          }}
        >
          {cardsData.map((item) => (
            <Box
              sx={{
                width: { md: "290px", xl: "300px" },
                height: { md: "600px", xs: "630px" },
                borderRadius: "12px 12px 12px 12px",
                marginTop: { md: "0", xs: "2.5em" },
                boxShadow: "20px 20px 25px 2px #00000040",
              }}
              gap={0}
            >
              <Box sx={{ height: "60%", width: "100%" }}>
                <img
                  src={item.img}
                  alt="imagem de exemplo"
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "12px 12px 0px 0px ",
                  }}
                />
              </Box>

              <Box
                sx={{
                  width: "100%",
                  height: { md: "44%", xs: "42%" },
                  backgroundColor: "white",
                  marginTop: "-0.5em",
                  paddingY: "2em",
                  paddingX: "1.5em",
                  borderRadius: "0px 0px 12px 12px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "24px",
                    color: "#F39200",
                    textTransform: "uppercase",
                  }}
                >
                  {item.title}
                </Typography>
                <Typography sx={{ marginTop: "1em", fontSize: "20px" }}>
                  {item.text}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
